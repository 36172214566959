import React, { useEffect, useState } from "react";
import './QuantitySelector.css';

function QuantitySelector({ quantityToApp }) {
    const [counter, setCounter] = useState(1);

    //increase counter
    const increase = () => {
        if (counter < 100) {
            setCounter((counter) => counter + 1);
        }
    };

    //decrease counter
    const decrease = () => {
        if (counter > 1) {
            setCounter((counter) => counter - 1);
        }
    };

    useEffect(() => {
        quantityToApp(counter);
    }, [counter, quantityToApp])


    return (
        <div className="counter">
            <div className="btn__container">
                <button className="control__btn" onClick={decrease}>-</button>
                <span className="counter__output">{counter}</span>
                <button className="control__btn" onClick={increase}>+</button>
            </div>
        </div>
    );
}

export default QuantitySelector;