import { useEffect, useState } from 'react';
import './App.css';
import contract from './contracts/Phazuki.json';
import { ethers } from 'ethers';
import "./font/MSBee-Regular.woff2";
import MintCounter from './components/MintCounter';
import QuantitySelector from './components/QuantitySelector';
import detectEthereumProvider from '@metamask/detect-provider'

const contractAddress = "0xdedBd22c74eb00e43987A045D3231E40bc144a9b";
const abi = contract.abi;

function App() {


  // State Management
  const [currentAccount, setCurrentAccount] = useState(null);
  const [currentChain, setCurrentChain] = useState(false);
  const [mintQuantity, setMintQuantity] = useState(1);
  const [supply, setSupply] = useState();


  const quantityToApp = (quantityFromSelector) => {
    setMintQuantity(quantityFromSelector);
    console.log("app:", mintQuantity)
  }

  const mintToApp = (supplyFromMintCounter) => {
    setSupply(supplyFromMintCounter);
    console.log("app:", supply)
  }


  const checkChain = async () => {
    const provider = await detectEthereumProvider();
    provider.on('chainChanged', (_chainId) => window.location.reload());

    if (!provider) {
      console.log("1: Please Install MetaMask");
      return;
    }
    else {
      console.log("Wallet exists!");
    }

    const chain = await provider.request({ method: 'eth_chainId' });
    console.log(chain);

    if (String(chain) === String("0x1")) {
      setCurrentChain(true);
    } else {
      console.log("Connect to Mainnet");
    }

  }



  // Wallet connection and buttons
  const checkWalletIsConnected = async () => {
    // const { ethereum } = window;
    const provider = await detectEthereumProvider();

    if (!provider) {
      console.log("1: Please Install MetaMask");
      return;
    }
    else {
      console.log("Wallet exists!");
    }

    const accounts = await provider.request({ method: 'eth_accounts' });

    if (accounts.length !== 0) {
      const account = accounts[0];
      console.log("Found an authorized account: ", account);
      setCurrentAccount(account);
    } else {
      console.log("No authorized account found");
    }

  }


  const connectWalletHandler = async () => {
    // const { ethereum } = window;
    const provider = await detectEthereumProvider();
    if (!provider) {
      alert("3: Please Install MetaMask");
    }

    try {
      const accounts = await provider.request({ method: 'eth_requestAccounts' });
      console.log("Found an acount address: ", accounts[0]);
      setCurrentAccount(accounts[0]);
    } catch (err) {
      console.log(err)
    }
  }

  const mintNftHandler = async () => {
    try {
      // const { ethereum } = window;
      const provider2 = await detectEthereumProvider();

      if (provider2) {
        const provider = new ethers.providers.Web3Provider(provider2);
        const signer = provider.getSigner();
        const nftContract = new ethers.Contract(contractAddress, abi, signer);

        // Calc money to be sent
        let price = 0.01;

        if ((supply + mintQuantity) <= 1000) {
          price = 0.01;
        }
        else if ((supply + mintQuantity) <= 2000) {
          price = 0.02;
        }
        else if ((supply + mintQuantity) <= 3000) {
          price = 0.03;
        }
        else if ((supply + mintQuantity) <= 4000) {
          price = 0.04;
        }
        else {
          price = 0.05;
        }

        console.log("Initialize payment");
        let nftTxn = await nftContract.mintNFTs(mintQuantity, { value: ethers.utils.parseEther(String(price * mintQuantity)) });

        console.log("Mining... please wait");
        await nftTxn.wait();

        console.log(`Mined, see transaction: https://rinkeby.etherscan.io/tx/${nftTxn.hash}`);

      } else {
        console.log("Ethereum object does not exist");
      }

    } catch (err) {
      alert(err);
    }

  }

  const connectWalletButton = () => {
    return (
      <button onClick={connectWalletHandler} className='cta-button connect-wallet-button'>
        connect wallet
      </button>
    )
  }
  // TODO: Return 2 different buttons for mint vs sold out
  const mintNftButton = () => {
    return (
      <button onClick={mintNftHandler} className='cta-button mint-nft-button'>
        mint
      </button>
    )
  }

  const soldOutButton = () => {
    return (
      <button className='cta-button sold-out-nft-button'>
        sold out
      </button>
    )
  }


  const quantitySelection = () => {
    return (
      <div>
        <QuantitySelector quantityToApp={quantityToApp}></QuantitySelector>
      </div>
    )
  }

  const connectToMain = () => {
    return (
      <div className='main-net'>
        Please connect to Ethereum Mainnet network!
      </div>
    )
  }

  const mainApp = () => {
    return (
      <div className='main-app'>
        <MintCounter mintToApp={mintToApp}></MintCounter>
        <div className='button-location'>
          {(supply === 10000) ? soldOutButton() : (currentAccount ? mintNftButton() : connectWalletButton())}
        </div>
        {(currentAccount && supply !== 10000) ? quantitySelection() : ""}
      </div>
    )
  }

  useEffect(() => {
    checkWalletIsConnected();
  }, [])

  useEffect(() => {
    checkChain();
  }, [currentChain])


  return (
    <div>
      {currentChain ? mainApp() : connectToMain()}
    </div>

  )
}

export default App;
