import { ethers } from 'ethers';
import React, { useEffect, useState } from 'react'
import contract from '../contracts/Phazuki.json';
import './MintCounter.css';

const contractAddress = "0xdedBd22c74eb00e43987A045D3231E40bc144a9b";
const abi = contract.abi;

function MintCounter({ mintToApp }) {
  const [count, setCount] = useState();
  console.log({ count });

  const getCount = async () => {
    const provider = new ethers.providers.Web3Provider(window.ethereum);
    const erc20 = new ethers.Contract(contractAddress, abi, provider);
    const totalSupply = await erc20.totalSupply();
    setCount(Number(totalSupply));


  }

  const totalSupplyHTML = () => {

    let varfontSize = 0;
    let varColor = "rgb(43, 201, 72)";
    if (count < 10) {
      varfontSize = 58;
    }
    else if (count < 100) {
      varfontSize = 58;
    }
    else if (count < 1000) {
      varfontSize = 51;
    }
    else if (count < 10000) {
      varfontSize = 47;
    }
    else if (count === 10000) {
      varfontSize = 42;
      varColor = "rgb(195, 53, 66)";
    }
    const styles = {
      fontSize: varfontSize.toString() + "px",
      color: varColor
    };
    const mintedStyles = {
      color: varColor
    }

    return (
      <div className="mintCounter">
        <div className="counter" style={styles}>{count}/10000</div>
        <div className="minted" style={mintedStyles}>minted</div>
      </div >
    )
  }

  const connectWalletHTML = () => {
    return (
      <div className="walletConnect">
        <div>Please Connect a MetaMask Wallet</div>
      </div>
    )
  }




  useEffect(() => {
    setInterval(() => {
      getCount();
    }, 1000);
  }, [])

  useEffect(() => {
    mintToApp(count);
  }, [count, mintToApp])



  return (
    <div>
      {count ? totalSupplyHTML() : connectWalletHTML()}
    </div>
  )
}

export default MintCounter